import { get } from 'lodash';

export const cryptoTypes = [
  { index: 0, id: 'all', name: 'All' },
  { index: 1, id: 'bitcoin', name: 'Bitcoin' },
  { index: 2, id: 'ethereum', name: 'Ethereum' },
  { index: 3, id: 'stellar', name: 'Stellar' },
];

export const addCryptoToAccountDeprecated = ({ currency, crypto, account }) => {
  currency.account = account.reference;
  currency.account_name = account.name;
  currency.account_label = account.label;
  let currencyCode = currency?.currency?.code;
  if (
    (currencyCode === 'XLM' && crypto.XLM) ||
    (crypto.XLM &&
      crypto.XLM.assets &&
      crypto.XLM.assets.indexOf(currencyCode) !== -1)
  ) {
    currency.crypto = 'XLM';
  } else if (
    (currencyCode === 'TXLM' && crypto.TXLM) ||
    (crypto.TXLM &&
      crypto.TXLM.assets &&
      crypto.TXLM.assets.indexOf(currencyCode) !== -1)
  ) {
    currency.crypto = 'TXLM';
  } else if (
    (currencyCode === 'ETH' && crypto.ETH) ||
    (currencyCode === 'TETH' && crypto.TETH) ||
    (currencyCode === 'XBT' && crypto.XBT) ||
    (currencyCode === 'TXBT' && crypto.TXBT)
  ) {
    currency.crypto = currencyCode;
  } else {
    currency.crypto = '';
  }

  return currency;
};

export const addCryptoToAccount = ({ currency, account, companyCurrency }) => {
  currency.account = account.reference;
  currency.account_name = account.name;
  currency.account_label = account.label;
  currency.metadata = companyCurrency?.metadata ?? {};
  currency.crypto = companyCurrency?.metadata?.native_context?.crypto
    ?.blockchain
    ? companyCurrency?.metadata?.native_context?.crypto
    : '';

  return currency;
};

export const checkIfCryptoDepricated = ({ currency, crypto }) => {
  let currencyCode = currency.currency.code;
  const currentCrypto = currency.crypto
    ? crypto[currency.crypto?.blockchain][currency.crypto?.network]
    : null;
  if (
    (currencyCode === 'XLM' && currentCrypto) ||
    currentCrypto?.assets?.includes(currencyCode)
  ) {
    return 'XLM';
  } else if (
    (currencyCode === 'TXLM' && currentCrypto) ||
    currentCrypto?.assets?.includes(currencyCode)
  ) {
    return 'TXLM';
  } else if (
    (currencyCode === 'ETH' && currentCrypto) ||
    (currencyCode === 'TETH' && currentCrypto) ||
    (currencyCode === 'XBT' && currentCrypto) ||
    (currencyCode === 'TXBT' && currentCrypto)
  ) {
    return currencyCode;
  } else {
    return '';
  }
};

export function checkIfStellar(currency) {
  // const cryptoCode = wallet?.crypto;
  // return cryptoCode.includes('XLM');
  return currency?.crypto?.blockchain === 'stellar';
}

export function stellarFederation(currency, crypto, isCrypto) {
  // const currencyCryptoCode = getCryptoCodeByMetadata(currency);
  let isFederated = false;
  let setUsername = false;

  let federatedAddress = '';
  let address = '';
  let memo = '';
  let federatedAddressLabel = '';

  let isStellar = isCrypto && checkIfStellar(currency);

  if (isCrypto && isStellar) {
    const currentCrypto =
      crypto?.[currency?.crypto?.blockchain][currency?.crypto?.network] ?? null;
    if (currentCrypto) {
      const { company, user } = currentCrypto;
      if (company && user) {
        setUsername = user.username ? false : true;
        address = user?.crypto?.public_address;
        memo = user?.crypto?.memo;
        if (company.is_federated) {
          isFederated = true;
          federatedAddress =
            (!setUsername ? user.username : user.memo) +
            '*' +
            company.federation_domain;
          federatedAddressLabel =
            'Stellar' +
            (currency?.crypto?.network === 'testnet' ? ' testnet' : '') +
            ' federated address';
        }
      }
    } else {
      isStellar = false;
    }
  }

  return {
    isFederated,
    isStellar,
    setUsername,
    federatedAddress,
    address,
    federatedAddressLabel,
    memo,
  };
}

export const getCryptoAddress = (currency, crypto, stellarTransactionType) => {
  switch (currency.crypto?.code) {
    case 'XBT':
    case 'TXBT':
      return getBitcoinAddress(currency, crypto);
    case 'XLM':
    case 'TXLM':
      return getStellarAddress(currency, crypto, stellarTransactionType);
    case 'ETH':
    case 'TETH':
      return getEthereumAddress(currency, crypto);
    default:
      return '';
  }
};

export const getCryptoCodeByMetadata = currency => {
  let value = '';
  const cryptoMetadata = currency.crypto;
  switch (cryptoMetadata?.blockchain) {
    case 'stellar':
      value = cryptoMetadata.network === 'mainnet' ? 'XLM' : 'TXLM';
      break;
    case 'bitcoin':
      value = cryptoMetadata.network === 'mainnet' ? 'XBT' : 'TXBT';
      break;
    case 'ethereum':
      value = cryptoMetadata.network === 'mainnet' ? 'ETH' : 'TETH';
      break;
    default:
      break;
  }

  return value;
};

export const getStellarAddress = (currency, crypto, stellarTransactionType) => {
  // const currencyCryptoCode = getCryptoCodeByMetadata(currency);
  const currencyBlockchain = currency?.crypto?.blockchain;
  const currencyNetwork = currency?.crypto?.network;
  let address = '';
  if (stellarTransactionType === 'public') {
    address = get(
      crypto,
      [currencyBlockchain, currencyNetwork, 'user', 'crypto', 'public_address'],
      '',
    );
  } else {
    const currentCrypto = get(
      crypto,
      [currencyBlockchain, currencyNetwork],
      null,
    );
    if (currentCrypto) {
      const { company, user } = currentCrypto;
      address =
        (user.username ? user.username : user.memo) +
        '*' +
        get(company, 'federation_domain', '');
    }
  }

  return address;
};

export const getBitcoinAddress = (currency, crypto) =>
  get(
    crypto,
    [
      currency.crypto?.blockchain,
      currency.crypto?.network,
      'user',
      'account_id',
    ],
    '',
  );

export const getEthereumAddress = (currency, crypto) =>
  get(
    crypto,
    [
      currency.crypto?.blockchain,
      currency.crypto?.network,
      'user',
      'crypto',
      'address',
    ],
    '',
  );

export function generateTxHashLink(tx_hash, type, testnet) {
  let link = 'https://';

  switch (type) {
    case 'bitcoin':
      link = link + 'live.blockcypher.com/btc' + (testnet ? '-testnet' : '');
      break;
    case 'stellar':
      link =
        link + 'stellar.expert/explorer/' + (testnet ? 'testnet' : 'public');
      break;
    default:
  }
  return link + '/tx/' + tx_hash + '/';
}
export function generateRecipientLink(address, type, testnet) {
  let link = 'https://';

  switch (type) {
    case 'bitcoin':
      link =
        link +
        'live.blockcypher.com/btc' +
        (testnet ? '-testnet' : '') +
        '/address';
      break;
    case 'stellar':
      link =
        link +
        'stellar.expert/explorer/' +
        (testnet ? 'testnet' : 'public') +
        '/account';
      break;
    default:
  }
  return link + '/' + address + '/';
}
