import * as React from 'react';

export default function Support(props) {
  const { width = 150, height = 150, primary } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 200 200">
      <defs>
        <clipPath id="clip-Support">
          <rect width="200" height="200" />
        </clipPath>
      </defs>
      <g id="Support" clipPath="url(#clip-Support)">
        <path
          id="Subtraction_22"
          data-name="Subtraction 22"
          d="M48.187,0h0a53.356,53.356,0,0,0-14.9,14.223,56.377,56.377,0,0,0-8.742,19.787c-.508,2.178-5.825,5.4-11.454,8.813C6.63,46.74-.049,50.788,0,53.606c.058,3.38,6.67,7.017,13.065,10.535l.009,0c2.775,1.527,5.644,3.106,7.859,4.611,2.48,1.686,3.884,3.116,4.293,4.372a58.265,58.265,0,0,0,21.945,29.691,61.007,61.007,0,0,0,16.876,8.321,64.661,64.661,0,0,0,19.422,2.945c.393,0,.791,0,1.181-.01a60.73,60.73,0,0,0,23.749-5.242,61.363,61.363,0,0,0,10.312-5.845,61.986,61.986,0,0,0,26.344-51.465,62.789,62.789,0,0,1-35.279,10.563c-.394,0-.791,0-1.182-.01a60.733,60.733,0,0,1-23.647-5.2,61.36,61.36,0,0,1-10.278-5.8,62,62,0,0,1-8.933-7.6,62.3,62.3,0,0,1-7.351-9.154,62.12,62.12,0,0,1-5.533-10.464A61.881,61.881,0,0,1,48.187,0Z"
          transform="translate(197.163 125.614) rotate(180)"
          fill="#fff"
        />
        <path
          id="primary"
          d="M48.187,0h0a53.356,53.356,0,0,0-14.9,14.223,56.377,56.377,0,0,0-8.742,19.787c-.508,2.178-5.825,5.4-11.454,8.813C6.63,46.74-.049,50.788,0,53.606c.058,3.38,6.67,7.017,13.065,10.535l.009,0c2.775,1.527,5.644,3.106,7.859,4.611,2.48,1.686,3.884,3.116,4.293,4.372a58.265,58.265,0,0,0,21.945,29.691,61.007,61.007,0,0,0,16.876,8.321,64.661,64.661,0,0,0,19.422,2.945c.393,0,.791,0,1.181-.01a60.73,60.73,0,0,0,23.749-5.242,61.363,61.363,0,0,0,10.312-5.845,61.986,61.986,0,0,0,26.344-51.465,62.789,62.789,0,0,1-35.279,10.563c-.394,0-.791,0-1.182-.01a60.733,60.733,0,0,1-23.647-5.2,61.36,61.36,0,0,1-10.278-5.8,62,62,0,0,1-8.933-7.6,62.3,62.3,0,0,1-7.351-9.154,62.12,62.12,0,0,1-5.533-10.464A61.881,61.881,0,0,1,48.187,0Z"
          transform="translate(197.163 125.614) rotate(180)"
          fill={primary}
          opacity="0.35"
        />
        <g
          id="Path_62588"
          data-name="Path 62588"
          transform="matrix(-0.017, 1, -1, -0.017, 150.049, 64.612)"
          fill="none">
          <path
            d="M61.939,0c34.208,0,61.939,27.53,61.939,61.49,0,28.73-15.731,51.894-42.776,58.7-4.664,1.174-13.895,24.883-19.163,24.883-6.363,0-13.958-23.045-19.96-24.883C16.918,112.516,0,89.133,0,61.49,0,27.53,27.731,0,61.939,0Z"
            stroke="none"
          />
          <path
            d="M 61.93857955932617 8 C 32.19673919677734 8 8 31.99544525146484 8 61.48983764648438 C 8 85.37281799316406 22.59616088867188 105.8881072998047 44.32136535644531 112.5397338867188 C 50.15924072265625 114.3277130126953 53.84678649902344 120.7622604370117 58.11663055419922 128.212890625 C 59.09897613525391 129.9270324707031 60.44219970703125 132.2709197998047 61.69272613525391 134.2279510498047 C 63.03866195678711 132.1271362304688 64.50574493408203 129.6083984375 65.57718658447266 127.7689208984375 C 70.47723388671875 119.3564758300781 73.71439361572266 113.7988662719727 79.14918518066406 112.4308395385742 C 101.8038177490234 106.7306518554688 115.8771591186523 87.21121215820312 115.8771591186523 61.4898681640625 C 115.8771591186523 31.99544525146484 91.680419921875 8 61.93857955932617 8 M 61.93857955932617 0 C 96.14631652832031 0 123.8771591186523 27.52994537353516 123.8771591186523 61.4898681640625 C 123.8771591186523 90.21994018554688 108.1461563110352 113.3841857910156 81.10124206542969 120.1890258789062 C 76.43737030029297 121.3629989624023 67.20655822753906 145.0718383789062 61.93857955932617 145.0718383789062 C 55.57595062255859 145.0718383789062 47.98076629638672 122.0272903442383 41.97863006591797 120.1890258789062 C 16.91773986816406 112.5160980224609 0 89.13323974609375 0 61.48983764648438 C 0 27.52994537353516 27.73084259033203 0 61.93857955932617 0 Z"
            stroke="none"
            fill="#020d88"
          />
        </g>
        <path
          id="Path_62586"
          data-name="Path 62586"
          d="M4.151-61.126H15.088a7.534,7.534,0,0,1,2.341-5.305,7.936,7.936,0,0,1,5.61-1.977,9.145,9.145,0,0,1,4.036.842A6.573,6.573,0,0,1,29.758-65.3a5.844,5.844,0,0,1,.948,3.308,8.03,8.03,0,0,1-.605,3.23,7.438,7.438,0,0,1-2.078,2.643,28.928,28.928,0,0,1-4.1,2.78,19.362,19.362,0,0,0-4.46,3.367,9.861,9.861,0,0,0-2.381,4.092,15.113,15.113,0,0,0-.424,5.384l.081,2.271H27.438V-40.3a8.39,8.39,0,0,1,.605-3.328,7.467,7.467,0,0,1,2.1-2.682,26.339,26.339,0,0,1,4.157-2.76,18.923,18.923,0,0,0,6.457-5.619,13.242,13.242,0,0,0,2.179-7.655,13.4,13.4,0,0,0-2.4-7.889,15.832,15.832,0,0,0-6.7-5.344,24.521,24.521,0,0,0-10.069-1.919A24.148,24.148,0,0,0,13.232-75.4a15.561,15.561,0,0,0-6.659,5.775A16.5,16.5,0,0,0,4.151-61.126ZM22.514-19.349a7.7,7.7,0,0,0,5.347-1.762,6.105,6.105,0,0,0,1.957-4.777,6.139,6.139,0,0,0-1.957-4.835,7.754,7.754,0,0,0-5.347-1.742,7.832,7.832,0,0,0-5.368,1.742,6.108,6.108,0,0,0-1.978,4.835,6.075,6.075,0,0,0,1.978,4.777A7.776,7.776,0,0,0,22.514-19.349Z"
          transform="translate(63.163 177.492)"
          fill="#020d88"
        />
      </g>
    </svg>
  );
}
