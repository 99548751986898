const en = {
  invoices: 'Invoices',
  billed_to: 'Billed to',
  invoice_number: 'Invoice #',
  due_date: 'Due date',
  redirect_url: 'Payment page',
  qty: 'QTY',
  unit_price: 'Unit price',
  sendInvoice: 'Send invoice',
  resendInvoice: 'Resend invoice',
  point_of_sale: 'Point of Sale',
  refundInvoice: 'Refund invoice',
};

export default en;
