const en = {
  displayCurrency: 'Display currency',
  primaryCurrency: 'Primary currency',
  notifications: 'Notifications',
  bankAccounts: 'Bank accounts',
  mfa: 'Multi-factor authentication',
  changePassword: 'Change password',
  externalAccounts: 'External accounts',
  externalAccountsSubtitle: 'All external accounts are used for withdrawals',
  bic: 'BIC number',
  iban: 'IBAN number',
  swift: 'Swift number',
  branch_code: 'Branch code',
  bank_code: 'Bank code',
  account_name: 'Account name',
  account_holder: 'Account holder',
  account_number: 'Account number',
  type: 'Account type',
  bank_name: 'Bank name',
  name: 'Name',
  number: 'Number',
  bank_number: 'Bank number',
  inc_number: 'Incorporation number',
  vat_number: 'VAT number',
  routing_number: 'Routing number',

  externalAccounts_title: 'External accounts',
  externalAccounts_description:
    'All your external accounts used for withdrawals. You can add multiple withdraw accounts',

  bank_title: 'Bank accounts',
  bank_description:
    'All your external bank accounts used for fiat withdrawals. You can add multiple bank accounts.',
  bank_empty: 'No bank accounts added',
  bank_new: 'Add new bank account',

  bitcoin_title: 'Bitcoin accounts',
  bitcoin_description:
    'All your external bitcoin accounts used for withdrawals. You can add multiple bitcoin accounts.',
  bitcoin_empty: 'No bitcoin accounts added',
  bitcoin_new: 'Add new bitcoin account',

  stellar_title: 'Stellar accounts',
  stellar_description:
    'All your external stellar accounts used for withdrawals. You can add multiple bitcoin accounts.',
  stellar_empty: 'No stellar accounts added',
  stellar_new: 'Add new stellar account',

  ethereum_title: 'Ethereum accounts',
  ethereum_description:
    'All your external bitcoin accounts used for withdrawals. You can add multiple bitcoin accounts.',
  ethereum_empty: 'No ethereum accounts added',

  preferences_title: 'Preferences',
  preferences_description: 'All your app preferences can be configured here.',

  displayCurrency_title: 'Display currency',
  displayCurrency_description: 'Your display currency for amounts.',

  primaryCurrecny_title: 'Primary currency',
  primaryCurrecny_description: 'The primary currency of the app.',

  notifications_title: 'Notifications',
  notifications_description:
    'Customise what notifications you receive for SMS, email and push notifications; if available.',

  security_title: 'Security',
  security_description:
    'Configure your security settings to ensure your app is secured to your liking',

  mfa_title: 'Multi-factor authentication',
  mfa_description:
    'Multi-factor authentication increases the security of your account by requiring a code (either SMS or from a token provider) above your normal password for authentication',
  mfa_landing_description:
    'Please choose the type of multi-factor authentication you would like to activate on your account.',

  password_title: 'Password',
  password_description: 'Change your password',

  devices_title: 'Devices',
  devices_description: 'Linked devices for receiving push notifications',
};

export default en;
