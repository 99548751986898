import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Text from 'components/outputs/Text';
import { Button } from 'components/inputs/Button';
import LottieImage from 'components/outputs/LottieImage';

const Fallback = props => {
  const classes = useStyles();
  const { onClick, hideRefresh = false } = props;

  return (
    <div className={classes.container}>
      <Text
        id="something_went_wrong"
        className={classes.text}
        color={'error'}
        align={'center'}
        variant={'h6'}
      />

      <Text
        className={classes.text}
        color={'error'}
        align={'center'}
        id="error_description"
      />

      <div className={classes.icon}>
        <LottieImage name={'error'} size={250} />
      </div>
      {!hideRefresh && (
        <div className={classes.button}>
          <Button
            color={'primary'}
            onClick={() => window.location.reload(false)}>
            REFRESH PAGE
          </Button>
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    // width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 16,
    //padding: theme.spacing(2),
  },
  text: {
    padding: 16,
    // padding: theme.spacing(2),
  },
  button: {
    padding: 16,
    //padding: theme.spacing(2),
  },
  icon: {
    // padding: 16,
    // padding: theme.spacing(2),
  },
}));

export default Fallback;
