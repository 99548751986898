const en = {
  // proof_of_identity: 'Proof of identity',
  // government_id: 'Government issued ID',
  // passport: 'Passport',
  // drivers_license: 'Drivers license',
  // advanced_proof_of_identity: 'Advanced proof of identity',
  // id_confirmation: 'ID confirmation photo (selfie)',
  // proof_of_address: 'Proof of address',
  // utility_bill: 'Utility bill',
  // bank_statement: 'Bank statement',
  // lease_or_rental_agreement: 'Lease or rental agreement',
  // municipal_rate_and_taxes: 'Municipal rate and taxes invoice',
  // mortgage_statement: 'Mortgage statement',
  // telephone: 'Telephone or cellular account',
  // insurance_policy: 'Insurance policy document',
  // retail_store: 'Statement of account (retail store)',
  // proof_of_income: 'Proof of income',
  // payslip: 'Payslip',
  // employment_letter: 'Employment letter',
  // financial_statement: 'Financial statement',
  // incorporation: 'Incorporation document',
  // upload_new: 'UPLOAD NEW',
  // currently_uploaded: 'Currently uploaded documents',
  // please_upload: 'Please upload one of the following documents. ',
  // please_upload_proof_of_address:
  //   'Your name and address must be clearly visible and be dated within the last 3 months.',
  // error_display: 'Unable to display preview of file',
  // upload_more_text: 'Does your document have multiple pages?',
  // upload_more_title: 'Additional pages?',
  // first_name: 'First name',
  // last_name: 'Last name',
  // nationality: 'Nationality',
  // birth_date: 'Birth date',
  // id_number: 'ID number',
  // language: 'Language',
  // email_address: 'Email address',
  // mobile_number: 'Mobile number',
  // address: 'Address',
  // bank_account: 'Bank account',
  // user: 'Verified user status',
  // basic_title: 'Basic info',
  // basic_description:
  //   'Basic info about yourself. To change any information, edit the fields and click the update button below',
  // centralBankNumber_title: 'Central bank number',
  // centralBankNumber_description: '',
  // finances_title: 'Proof of income',
  // finances_description: '',
  // profile_title: 'Profile pic',
  // profile_description:
  //   'Take a selfie or upload an image of yourself and click the Update button below',
  // tiers_title: 'Tiers and limits',
  // tiers_description:
  //   'Fulfill the KYC requirements to level up your tier. Certain actions are limited by tier. See the Limits tab to see what limits are set for which tier',
  // documents_title: 'Verification documents',
  // documents_description:
  //   'Upload a document to verify your identity, address or income. You can view the status of your documents to see if it is under review, accepted or rejected',
  // mobiles_empty: 'No mobile numbers added yet',
  // mobiles_title: 'Mobile numbers',
  // mobiles_description:
  //   'You can add multiple mobile numbers to this list. Hover over a mobile number to remove it',
  // mobiles_new: 'Add new mobile number',
  // mobiles_edit: 'Edit mobile number',
  // mobiles_verify: 'Verify mobile number',
  // mobiles_delete: 'Delete mobile number',
  // emails_empty: 'No email addresses added yet',
  // emails_title: 'Email addresses',
  // emails_description:
  //   'You can add multiple email addresses to this list. Hover over an email address to remove it',
  // emails_new: 'Add new email address',
  // emails_edit: 'Edit email address',
  // emails_verify: 'Verify email address',
  // emails_delete: 'Delete email address',
  // addresses_empty: 'No addresses added yet',
  // addresses_title: 'Addresses',
  // addresses_description:
  //   'Your addresses. You can add multiple addresses to this list. Hover over an address to edit or delete it',
  // addresses_new: 'Add new address',
  // addresses_edit: 'Edit address',
  // addresses_delete: 'Delete address',
  // upload_document: 'UPLOAD DOCUMENT',
  // upload_new_document: 'UPLOAD NEW DOCUMENT',
  // submit_documents: 'SUBMIT DOCUMENTS',
  // cannot_preview: 'Unable to preview this document',
  // refer_title: '',
  // refer_description: '',
};

export default en;
