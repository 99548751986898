import React, { Component } from 'react';
import * as Sentry from '@sentry/react';
import Fallback from './Fallback';

export default function ErrorBoundary(props) {
  return (
    <Sentry.ErrorBoundary fallback={Fallback}>
      {props.children}
    </Sentry.ErrorBoundary>
  );
}

// class ErrorBoundary extends Component {
//   constructor(props) {
//     super(props);
//     this.state = { eventId: null };
//   }

//   static getDerivedStateFromError(error) {
//     return { hasError: true };
//   }

//   componentDidCatch(error, errorInfo) {
//     Sentry.withScope(scope => {
//       scope.setExtras(errorInfo);
//       const eventId = Sentry.captureException(error);
//       this.setState({ eventId });
//     });
//   }

//   render() {
//     if (this.state.hasError) {
//       //render fallback UI
//       return (
//         <Fallback
//           onClick={() =>
//             Sentry.showReportDialog({ eventId: this.state.eventId })
//           }
//         />
//       );
//     }

//     //when there's not an error, render children untouched
//     return this.props.children;
//   }
// }

// export default ErrorBoundary;
